//profile nav
import ProfileNav from '../components/ProfileNav'
import ProfileSidebar from '../components/ProfileSidebar'
//styles
import "./profile-layout.css"

function ProfileLayout({ children }){
	return (
		<div className="profile-layout">
			{/*<div className="account-not-confirmed">
				<p>🚫 حسابك غير مفعل, لن تتحصل على الميزات التالية: <span>نشر كتب</span> <span>نشر دروس</span> <span>بدأ بث مباشر</span> <span>مطالبة بأرباح</span> ... يرجي تفعيل الحساب من <a href="/">هنا</a></p>
				<p><a href="">زيارة مركز النجيب سكول للمساعدة</a></p>
			</div>*/}
			<ProfileNav />
			<main className="main-profile-layout" >
				<ProfileSidebar />
				<article className="article-profile-layout">
					{children}
				</article>
			</main>
		</div>
	)
}


export default ProfileLayout;