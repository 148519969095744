import { useState, useEffect, useRef } from "react"
import ProfileLayout from '../../layouts/profileLayout.jsx'
import '../css/subscriber.css'
import io from 'socket.io-client';



const socket = io('https://localhost:5000');
function WatchStream(){
	let remoteVideoRef = useRef(null)
	const peerConnection = useRef(new RTCPeerConnection());

	useEffect(() => {
		socket.emit('subscribe')
		socket.on('offer', async(offer) => {
			console.log('offer')
			await peerConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
		
            const answer = await peerConnection.current.createAnswer();
            await peerConnection.current.setLocalDescription(answer);

		})


		   // Handle remote stream
        peerConnection.current.ontrack = (event) => {
            // Ensure a MediaStream is created and the track is added to it
            const mediaStream = remoteVideoRef.current.srcObject || new MediaStream();
            mediaStream.addTrack(event.track);

            // Set the MediaStream to the video element
            remoteVideoRef.current.srcObject = mediaStream;
        };

        return () => {
            socket.disconnect();
        };

	}, [])

	return(
		<div>
			<ProfileLayout>
				<div>	
					<p>watch live here</p>
					<div>
                        <video className="rop" ref={remoteVideoRef} autoPlay playsInline/>
					</div>
				</div>
			</ProfileLayout>
		</div>
	)
}

export default WatchStream;