import { useNavigate } from 'react-router-dom';
import './css/nav.css'
//MUI
import LockOpenIcon from '@mui/icons-material/LockOpen';


function Nav(){
    const navigate = useNavigate()
    return( 
         <nav className="nav">
            <ul>
                <li onClick={() => navigate('/homepage')} className="li-item">الرئيسية</li>
                <li onClick={() => navigate('/library')} className="li-item">المكتبة</li>
                <li className="li-item" onClick={() => navigate('/live')}>بث مباشر</li>
                <li className="li-item" onClick={() => navigate('about-us')}>أعلن معنا</li>
            </ul>
           
            <div onClick={() => navigate('/')} className="logo-container">
                <img src="/logo.png" alt="png illustration of school logo" />
            </div>

            <ul>
                <li className="li-item" onClick={() => navigate('about-us')}>من نحن</li>
                <li className="li-item">تواصل معنا</li>
               {/* <a href="/login?t=s">
                    <li className="excluded-li-item"  >
                        <LockOpenIcon />
                        <p>دخول الطالب</p>
                    </li>
                </a>*/}
                <a href="/login?t=t">
                    <li className="excluded-li-item" >
                        <LockOpenIcon />
                        <p>دخول الإستاذ</p>
                    </li>
                </a>
            </ul>

        </nav>
    )
}

export default Nav;