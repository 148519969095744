import './css/student-overview.css'
//layout
import ProfileLayout from '../../layouts/profileLayout.jsx'
//components
import ProfileNav from '../../components/ProfileNav'
import EventsLoader from '../../components/dedicated/EventsLoader'
import Teachers from '../../components/dedicated/Teachers'
import LatestCourses from '../../components/dedicated/LatestCourses'
import Exams from '../../components/dedicated/Exams'
import Library from '../../components/dedicated/Library'

function Overview(){
	return(
		<div className="overview">
			<ProfileLayout>
				<main className="overview-main">
					<div className="overview-main-content">
						<LatestCourses />
						{/*<Teachers />*/}
						{/*<Exams />*/}
						<div className="banner">
							<img src="/banners/b.jpg" alt="picture of school banner" />
						</div>
					</div>
					<aside className="overview-aside">
						<EventsLoader />
						{/*<Library />*/}
					</aside>
				</main>
			</ProfileLayout>
		</div>
	)
}

export default Overview;