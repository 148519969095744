import ProfileLayout from '../../layouts/profileLayout.jsx'

import { useEffect, useState, useRef } from 'react'
import io from 'socket.io-client';
// import "./App.css"
// components
// import ProfileNav from './components/Nav'
//MUI
import Alert from '@mui/material/Alert';
import SendIcon from '@mui/icons-material/Send';
import MenuIcon from '@mui/icons-material/Menu';
//css
import '../css/broadcast.css'
//react-helmet
import { Helmet } from 'react-helmet'


const socket = io('https://localhost:5000');
// const socket = io('https://nadjib-school-online-server-2.onrender.com');

function Stream() {
    const [input, setInput] = useState("")
    const [idp, setId] = useState(null)

    function handleSendMessage(){
        socket.emit('broadcast-channel', {
            id:idp,
            username: "test",
            content: input
        })
        setInput("")
    }

    let localVideoRef = useRef()
    let remoteAudioRef = useRef()
    let remoteVideoRef = useRef(null);
    const messagesEndRef = useRef(); // Ref to scroll to

    const [messages, setMessages] = useState([
        
    ])
    
    let [alert, setAlert] = useState({
        state: false,
        message: "",
        type: ""
    })

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        async function initializeBroadcast(){
            try {
                let stream = await navigator.mediaDevices.getUserMedia({video:true,audio:true})
                if(localVideoRef.current) {
                    localVideoRef.current.srcObject = stream
                }
                const pc = new RTCPeerConnection()

                stream.getTracks().forEach(track => {
                    pc.addTrack(track, stream)
                })

                const offer = await pc.createOffer();
                await pc.setLocalDescription(offer);
                socket.emit('offer', offer);

            }catch(err){
                console.error(err)
            }
        }
        initializeBroadcast()
    }, [])    




 return (
         <ProfileLayout>
             <Helmet>
                <meta charset="utf-8" />
                <title>بث مباشر لحصة تدريس | أكادمية النجيب سكول</title>
                <link rel="canonical" href="https://najibschool.com/about-us" />
            </Helmet>
            <main className="broadcast">
               <div className="broadcast-video-container">
                    <div className="header">
                        <p>درس في مادة الرياضيات حول الدوال الخطية و الدوال التآلفية للسنة الثالثة متوسط</p>
                        <div className="broadcast-video-container-tags">
                            <p>الرياضيات</p>
                            <p>الثالثة متوسط</p>
                        </div>
                    </div>
                                       
                    <div className="video-element-wrapper">
                        <video className="iop" ref={localVideoRef} autoPlay playsInline/>
                    </div>
                </div>

              <div className="broadcast-messages-container">
                    <div className="header">
                        <p>الدردشة مع الطلاب</p>
                        <MenuIcon />
                    </div>
                    <div className="messages-displayer">
                        {
                            messages.map((msg, index) => 
                                <div className={`brodcasted-message ${msg.id === idp ? `my-message` : `not-my-message`}`} key={index}>
                                    <p className="username">{msg.username}</p>
                                    <p className="content">{msg.content}</p>
                                </div>
                            )
                        }
                        <div 
                        ref={messagesEndRef}
                         ></div>
                    </div>
                    <div className="message-composer">
                        <input value={input} onChange={e => setInput(e.target.value)} placeholder="أكتب رسالة..."/>
                        <SendIcon onClick={() => handleSendMessage()}/>
                    </div>
                </div>
               
                            {/*<video 
                                ref={remoteVideoRef} 
                                autoPlay 
                                playsInline/>*/}
                        {/*<div>

                        <p>local video</p>
                            <video 
                                ref={localVideoRef} 
                                autoPlay 
                                    playsInline/>
                        </div>*/}

                            {/*<video 
                                ref={remoteAudioRef} 
                                autoPlay 
                                    playsInline/>*/}

            </main>
            </ProfileLayout>
    );
}

export default Stream;

