import './css/teachers.css'
import { useNavigate } from 'react-router-dom'
//MUI
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import GroupsIcon from '@mui/icons-material/Groups';


function Teachers(){
	const navigate = useNavigate()

	const data = [
		{
			name: "احمد  ",
			spec: "أستاذ الرياضيات",
			rating: 4,
			badges: ["أستاذ متميز","أستاذ محترف"],
			img_url:"/images/teachers/t4.jpeg"
		},
		{
			name: "ابراهيم  ",
			spec: "أستاذ اللغة العربية",
			rating: 2,
			badges: ["أستاذ جديد"],
			img_url:"/images/teachers/t5.jpg"
		},
		{
			name: "ياسين ",
			spec: "أستاذ الفيزياء",
			rating: 4,
			badges: ["أستاذ خبير","أستاذ متميز","أستاذ صاعد"],
			img_url:"/images/teachers/t6.jpg"
		},
		{
			name: "خالد ",
			spec: "أستاذ الفلسفة",
			rating: 4,
			badges: ["أستاذ الفلسفة","أستاذ صاعد"],
			img_url:"/images/teachers/t1.jpg"
		}
	]

function set_rating(rate){
	let light_stars = rate
	let dark_stars = 5 - rate

	let light_array = Array.from({ length:light_stars}, (_, index) => <StarIcon />)
	let dark_array = Array.from({ length:dark_stars}, (_, index) => <StarBorderIcon />)
	let full_array = [...light_array, ...dark_array]
	return full_array
}

// function set_badges(badgesCount){
// 	let badges_array = Array.from({length: badgesCount},(_, index) => <img src="/badges/empty-badge.svg" alt="png illustration of a badge" />)
// }

	return(
		<div className="teachers">
			<header className="teachers-header">
				<p><GroupsIcon/> الأساتذة</p>
				<button className="more-teachers">
					<p onClick={() => navigate('/student/all-teachers')}>المزيد من الأساتذة</p>
					<ArrowBackIosNewIcon />
				</button>
			</header>
			<div>
			{
				data.map(t =>
					<div className="teacher-row">
						<section className="account-data">
							<div className="img-container">
								<img src={t.img_url} alt="profile picture of a teacher" />
							</div>
							<div>
								<p className="name">{t.name}</p>
								<p className="spec">{t.spec}</p>
							</div>
						</section>
						<section className="rating-section">
							<p className="section-name">التقييم</p>
							<div className="stars-container">	
							{
								set_rating(t.rating)
							}
							</div>
						</section>
						<section className="badges-section">
							<p className="section-name">الإنجازات</p>
							<div className="badges-container">
								{/*<img src="/badges/empty-badge.svg" alt="png illustration of a badge" />*/}
								{
									t.badges.map(b => 
										<div className="badge-element">
											<img src="/badges/empty-badge.svg" alt="png illustration of a badge" />
											<p className="badge-name">{b}</p>
										</div>
									)
								}
							</div>
						</section>
						<MoreVertIcon />
					</div>
				)
			}				
			</div>
		</div>
	)
}

export default Teachers;