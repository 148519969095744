import { useNavigate } from 'react-router-dom'
// css
import './css/all-teachers.css'
//Layout
import ProfileLayout from '../../layouts/profileLayout'
//MUI
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

function AllTeachers(){
	const navigate = useNavigate()

	const data = [
		{
			name: "احمد  ",
			spec: " الرياضيات",
			rating: 1,
			badges: ["أستاذ متميز","أستاذ محترف"],
			levle: "أستاذ جديد",
			lvl_index:1,
			join_index:"منذ سنة",
			books_count: 0,
			exams_count: 2
		},
		{
			name: "ابراهيم  ",
			spec: " اللغة العربية",
			rating: 2,
			badges: ["أستاذ جديد"],
			levle: "أستاذ صاعد",
			lvl_index:2,
			join_index:"منذ 6 أشهر",
			books_count: 2,
			exams_count: 11
		},
		{
			name: "ياسين ",
			spec: " الفيزياء",
			rating: 5,
			badges: ["أستاذ خبير","أستاذ متميز","أستاذ صاعد"],
			levle: "بروفيسور",
			lvl_index:3,
			join_index:'منذ 10 سنوات',
			books_count: 137,
			exams_count: 90

		},
		{
			name: "سمير ",
			spec: " الفيزياء",
			rating: 1,
			badges: ["أستاذ خبير","أستاذ متميز","أستاذ صاعد"],
			levle: "بدون انجازات",
			lvl_index:0,
			join_index:'منذ  3 أشهر',
			books_count: 2,
			exams_count: 0

		},
		{
			name: "خالد ",
			spec: " الفلسفة",
			rating: 4,
			badges: ["أستاذ الفلسفة","أستاذ صاعد"],
			levle: "أستاذ محترف",
			lvl_index:4,
			join_index:"منذ أسبوع"	,
			books_count: 12,
			exams_count: 22
		},
		{
			name: "احمد  ",
			spec: " الرياضيات",
			rating: 1,
			badges: ["أستاذ متميز","أستاذ محترف"],
			levle: "أستاذ جديد",
			lvl_index:1,
			join_index:"منذ سنة",
			books_count: 0,
			exams_count: 2
		},
		{
			name: "ابراهيم  ",
			spec: " اللغة العربية",
			rating: 2,
			badges: ["أستاذ جديد"],
			levle: "أستاذ صاعد",
			lvl_index:2,
			join_index:"منذ 6 أشهر",
			books_count: 2,
			exams_count: 11
		},
		{
			name: "ياسين ",
			spec: " الفيزياء",
			rating: 5,
			badges: ["أستاذ خبير","أستاذ متميز","أستاذ صاعد"],
			levle: "بروفيسور",
			lvl_index:3,
			join_index:'منذ 10 سنوات',
			books_count: 137,
			exams_count: 90

		},
		{
			name: "خالد ",
			spec: " الفلسفة",
			rating: 4,
			badges: ["أستاذ الفلسفة","أستاذ صاعد"],
			levle: "أستاذ محترف",
			lvl_index:4,
			join_index:"منذ أسبوع"	,
			books_count: 12,
			exams_count: 22
		},
		{
			name: "احمد  ",
			spec: " الرياضيات",
			rating: 1,
			badges: ["أستاذ متميز","أستاذ محترف"],
			levle: "أستاذ جديد",
			lvl_index:1,
			join_index:"منذ سنة",
			books_count: 0,
			exams_count: 2
		},
		{
			name: "ابراهيم  ",
			spec: " اللغة العربية",
			rating: 2,
			badges: ["أستاذ جديد"],
			levle: "أستاذ صاعد",
			lvl_index:2,
			join_index:"منذ 6 أشهر",
			books_count: 2,
			exams_count: 11
		},
		{
			name: "ياسين ",
			spec: " الفيزياء",
			rating: 5,
			badges: ["أستاذ خبير","أستاذ متميز","أستاذ صاعد"],
			levle: "بروفيسور",
			lvl_index:3,
			join_index:'منذ 10 سنوات',
			books_count: 137,
			exams_count: 90

		},
		{
			name: "خالد ",
			spec: " الفلسفة",
			rating: 4,
			badges: ["أستاذ الفلسفة","أستاذ صاعد"],
			levle: "أستاذ محترف",
			lvl_index:4,
			join_index:"منذ أسبوع"	,
			books_count: 12,
			exams_count: 22
		},
		{
			name: "احمد  ",
			spec: " الرياضيات",
			rating: 1,
			badges: ["أستاذ متميز","أستاذ محترف"],
			levle: "أستاذ جديد",
			lvl_index:1,
			join_index:"منذ سنة",
			books_count: 0,
			exams_count: 2
		},
		{
			name: "ابراهيم  ",
			spec: " اللغة العربية",
			rating: 2,
			badges: ["أستاذ جديد"],
			levle: "أستاذ صاعد",
			lvl_index:2,
			join_index:"منذ 6 أشهر",
			books_count: 2,
			exams_count: 11
		},
		{
			name: "ياسين ",
			spec: " الفيزياء",
			rating: 5,
			badges: ["أستاذ خبير","أستاذ متميز","أستاذ صاعد"],
			levle: "بروفيسور",
			lvl_index:3,
			join_index:'منذ 10 سنوات',
			books_count: 137,
			exams_count: 90

		},
		{
			name: "خالد ",
			spec: " الفلسفة",
			rating: 4,
			badges: ["أستاذ الفلسفة","أستاذ صاعد"],
			levle: "أستاذ محترف",
			lvl_index:4,
			join_index:"منذ أسبوع"	,
			books_count: 12,
			exams_count: 22
		}
	]

function set_rating(rate){
	let light_stars = rate
	let dark_stars = 5 - rate

	let light_array = Array.from({ length:light_stars}, (_, index) => <StarIcon />)
	let dark_array = Array.from({ length:dark_stars}, (_, index) => <StarBorderIcon />)
	let full_array = [...light_array, ...dark_array]
	return full_array
}

function set_levle(levle){
	switch(levle){
	case 0:
			return <p style={{
					color:'#707070',
					border:'1px solid #9e9e9e',
					background:'#e3e3e3'
				}}>بدون إنجازات</p>
			break;
		case 1:
			return <p style={{
					color:'#51999E',
					border:'1px solid #51999E',
					background:'#C9F2F5'
				}}>أستاذ جديد</p>
			break;
		case 2:
			return <p style={{
					color:'#9E8143',
					border:'1px solid #9E8143',
					background:'#F2E4C4'
				}}>أستاذ صاعد</p>
			break;
		case 3:
			return <p style={{
					color:'#5333A3',
					border:'1px solid #5333A3',
					background:'#DFDBF5'
				}}>بروفيسور</p>
			break;
		case 4:
			return <p style={{
					color:'#D6342C',
					border:'1px solid #D6342C',
					background:'#F6D6E0'
				}}>أستاذ محترف</p>
			break;
	}
}

	return(
		<div className="all-teachers">
			<ProfileLayout>
				<table className="all-listed-teachers-table">
					<thead>
						<tr>
							<th className="teacher-info"><p>الأستاذ</p></th>
							<th className="teacher-spec"><p>المادة</p></th>
							<th><p>التقييم</p></th>
							<th><p>المستوى </p></th>
							<th><p>تاريخ الانظمام</p></th>
							<th><p>الكتب المنشورة</p></th>
							<th><p>الامتحانات المنشورة</p></th>
						</tr>
					</thead>
					<tbody>
					{
						data.map(t =>
							<tr className="listed-teacher" onClick={() => navigate('55')}>
								<th className="teacher-info">
									<div className="img-container">
										<img src="/teacher-avatar.svg" alt="svg illustration of teacher avatar" />
									</div>
									<p>{t.name}</p>
								</th>
								<th><p>{t.spec}</p></th>
								<th><p className="rating-container">{set_rating(t.rating)}</p></th>
								<th className="teacher-levle">{set_levle(t.lvl_index)}</th>
								<th><p className="join-date">{t.join_index}</p></th>
								<th><p><a>{t.books_count}</a></p></th>
								<th><p><a>{t.exams_count}</a></p></th>
							</tr>
						)
					}				
					</tbody>
				</table>
			</ProfileLayout>
		</div>
	)
}

export default AllTeachers;