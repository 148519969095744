// css
import './css/teacher-view.css'
//MUI
import ProfileLayout from '../../layouts/profileLayout'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WarningIcon from '@mui/icons-material/Warning';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import SmsIcon from '@mui/icons-material/Sms';

function TeacherView(){

	const badges = ["أستاذ الفلسفة","أستاذ صاعد"]

function set_rating(rate){
	let light_stars = rate
	let dark_stars = 5 - rate

	let light_array = Array.from({ length:light_stars}, (_, index) => <StarIcon style={{color:"#ed13e2"}} />)
	let dark_array = Array.from({ length:dark_stars}, (_, index) => <StarBorderIcon style={{color:"#ffabfb"}} />)
	let full_array = [...light_array, ...dark_array]
	return full_array
}

	return(
		<div>
			<ProfileLayout>
				<div className="teacher-view">
					<div className="teacher-view-col-1">
						{/*<header>
							header 1
						</header>*/}
						<main>
							<section className="teacher-view-teacher">
								<header className="section-title" id="section-title-1">
									من أنا 
								</header>
								<article className="teacher-view-teacher-info">
									<div className="img-container">
										<img src="/teacher-avatar.svg" alt="user personal profile picture" />
									</div>
									<div>
										<p>عين قوير ياسين</p>
										<p>أستاذ مادة الرياضيات</p>
										<p>معنا منذ 03 سنوات</p>
									</div>
									<div>
										<p className="spec">السنة الثانية متوسط</p>
										<p className="lvl">أستاذ صاعد</p>
										<div>
											<button className="send-message-btn">
												<SmsIcon/>
												<p>أرسل رسالة</p>
											</button>
										</div>	
									</div>
								</article>
							</section>

							<section className="contact-teacher">
								<header className="section-title" id="section-title-2">
									تواصل معي
								</header>
								<article>
									<div>
										<PhoneAndroidIcon />
										<p>07 99 65 38 78</p>
									</div>
									<div>
										<EmailIcon />
										<p>yassine_99@gmail.com</p>
									</div>
									<div>
										<LocationOnIcon />
										<p>سيق - معسكر</p>
									</div>
								</article>
							</section>

							<section className="badges-container">
								<p className="section-title" id="section-title-3"> الإنجازات	</p>
								<article>
									{
										badges.map(b => 
											<div className="badge-element">
												<img src="/badges/empty-badge.svg" alt="png illustration of a badge" />
												<p className="badge-name">{b}</p>
											</div>
										)
									}
								</article>
							</section>

							

						</main>
					</div>
					<div className="teacher-view-col-2">
						{/*<header >
							header 2
						</header>*/}
						<main>

							<section className="ratting">
								<header className="section-title" id="section-title-5">
									التقييمات
								</header>
								<article>
									<div>
										{set_rating(4)}
										<p>4.2k صوت</p>
									</div>
									<div className="buttons-container">
										<FavoriteIcon />
										<HeartBrokenIcon />
									</div>
								</article>
							</section>

							<section className="posts">
								<header className="section-title" id="section-title-4">
									المنشورات
								</header>
								<article>
									<div>
										<img src="/icons/exam.svg" alt="png illusration of an exam paper" />
										<p>27 فرض</p>
									</div>
									<div>
										<img src="/icons/exam.svg" alt="png illusration of an exam paper" />
										<p>17 اختبار</p>
									</div>
									<div>
										<img src="/icons/exam.svg" alt="png illusration of an exam paper" />
										<p>04 كتب</p>
									</div>
									<div>
										<img src="/icons/exam.svg" alt="png illusration of an exam paper" />
										<p>01 مقالة</p>
									</div>
								</article>
							</section>

							<section className="reviews">
								<header className="section-title" id="section-title-6">
									الآراء
								</header>
								<article>
									<div className="review-row">
										<div className="img-container">
											<img src="/student-avatar.svg" alt="png illusration of an exam paper" />
										</div>
										<div>
											<p className="review-row-name">محمد</p>
											<p className="review-row-content">ما شاء الله نعم الاستاذ  مستوى راقي جدا</p>
										</div>
									</div>

									<div className="review-row">
										<div className="img-container">
											<img src="/student-avatar.svg" alt="png illusration of an exam paper" />
										</div>
										<div>
											<p className="review-row-name">محمد</p>
											<p className="review-row-content">احسن استاذ في المادة بالتوفيق</p>
										</div>
									</div>

									<div className="review-row">
										<div className="img-container">
											<img src="/student-avatar.svg" alt="png illusration of an exam paper" />
										</div>
										<div>
											<p className="review-row-name">محمد</p>
											<p className="review-row-content">روعة استاذ في قمة المستوى</p>
										</div>
									</div>
								</article>
							</section>

							<section className="signle-teacher">
								<header >
									<WarningIcon />
									<p>أبلغ عن هذا الاستاذ</p>
								</header>
								<article>
									<p>في حال انتهك هذا الاستاذ معايير مجتمعنا او قام بنشر اشياء غير لائقة قم بالابلاغ عنه فورا !</p>
								</article>
							</section>
						</main>
					</div>
				</div>
			</ProfileLayout>
		</div>
	)
}

export default TeacherView;