import "./css/footer.css"

 function Footer(){
 	return(
 		<footer>
        <div className="top-section">
            <div className="info">
           	 <img className="footer-img" src="/logo.png" alt="nadjin school online png logo" />
           	 <p>منصة إلكترونية عربية مختصة في التدريس عن بعد</p>
          	</div>

              <ul className="ul-list">
                <p>حلول</p>
                <li><a href="/"><p>المكتبة</p></a></li>
                <li><a href="/"><p>دروس لايف</p></a></li>
                <li><a href="/"><p>الإعلان معنا</p></a></li>
                <li><a href="/"><p>أفكار</p></a></li>
              </ul>

              <ul className="ul-list">
                <p>الدعم</p>
                <li><a href="/"><p>رزنامة الإمتحانات</p></a></li>
                <li><a href="/"><p>الأطوار الدراسية</p></a></li>
                <li><a href="/"><p>الأساتذة</p></a></li>
                <li><a href="/"><p>مديرية التربية و التعليم</p></a></li>
              </ul>

            

              <ul className="ul-list">
                <p>قانوني</p>
                <li><a href="/"><p>من نحن</p></a></li>
                <li><a href="/"><p>تواصل معنا</p></a></li>
                <li><a href="/"><p>تقديم شكوى</p></a></li>
              </ul>
        </div>

        <div className="bottom-section">
          جميع الحقوقة محفوظة لمنصة النجيب سكول | 2024
        </div>
 		</footer>
 	)
 }

 export default Footer;


