// css
import './css/library-filter.css'


function LibraryFilter(){
	return(
		<div className="libraryFilter">
			<select 
				id="library-select-element-branch">
				<option value={'0'}>الطور الدراسي</option>
		        <option value={'2024'}>الطور الابتدائي</option>
		        <option value={'2023'}>الطور المتوسط</option>
		        <option value={'2023'}>الطور الثانوي</option>
		        
			</select>

			<select
			    id="library-select-element-level">
		        <option value={"0"}>المستوى</option>
		        <option value={'0ap'}>السنة الاولى</option>
		        <option value={'1ap'}>السنة الثانية</option>
		        <option value={'2ap'}>السنة الثالثة</option>
		        <option value={'3ap'}>السنة الرابعة</option>
		        <option value={'4ap'}>السنة الخامسة</option>
		    </select>

			
		    <select
				id="library-select-element-subject">
		        <option value={"0"}>الفصل</option>
		        <option value={'0ap'}>الفصل الاول</option>
		        <option value={'1ap'}>الفصل الثاني</option>
		        <option value={'2ap'}>الفصل الثالث</option>
		        
		    </select>

			<select
				id="library-select-element-subject">
		        <option value={"0"}>المادة</option>
		        <option value={'0ap'}>اللغة العربية</option>
		        <option value={'1ap'}>اللغة الإنجليزية</option>
		        <option value={'2ap'}>العلوم الطبيعية و الحياة</option>
		        <option value={'3ap'}>التربية المدنية</option>
		        <option value={'4ap'}>التربية الاسلامية</option>
		        <option value={'5ap'}>التربية الفنية</option>
		        <option value={'1am'}>الإعلام الآلي</option>
		        <option value={'2am'}>التاريخ</option>
		        <option value={'3am'}>الجغرافيا</option>
		    </select>


		    <select
			    id="library-select-element-level">
		        <option value={'0'}>النوع</option>
		        <option value={'1'}>إختبار</option>
		        <option value={'2'}>فرض</option>
		    </select>

		    <button className="submit-btn">بحث</button>
		</div>
	)
}


export default LibraryFilter;