// css
import './css/all-courses.css'
import ProfileLayout from "../../layouts/profileLayout"
//UI components
import CoursesFilter from '../../components/filters/CoursesFilter'
//DB
import coursesDB from '../../db/courses.json'
//MUI
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

function AllCourses(){

	function set_rating(rate){
		let light_stars = rate
		let dark_stars = 5 - rate

		let light_array = Array.from({ length:light_stars}, (_, index) => <StarIcon />)
		let dark_array = Array.from({ length:dark_stars}, (_, index) => <StarBorderIcon />)
		let full_array = [...light_array, ...dark_array]
		return full_array
	}

	return(
		<div className="all-listed-courses">
			<ProfileLayout>
				<CoursesFilter />
				<div>
					{/*<section className="listed-courses-section" id="listed-courses-section-100">
						<p>الرياضيات</p>
					</section>*/}
					<div className="all-listed-courses-container">
						{
							coursesDB.filter(c => c.subject_id === 100).map((c, index) => 
								<div className="listed-course-wrapper">
									<div href="/" className="course-element">
										<div className="course-element-cover">
											<img src={c.cover_url} alt="course cover image" />
										</div>
										<div className="course-element-content">
											<div className="image-container">
												<img src={c.owner.img_url} alt="course teach profile picture" />
											</div>
											<div>
												<p className="title">{c.courseName}</p>
												<p className="ratting">{set_rating(c.ratting)}</p>
												<p className="timestamp">{c.timeStamp}</p>
												<p className="visits"><VisibilityIcon />{c.visits}</p>
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>

					{/*<section className="listed-courses-section" id="listed-courses-section-101">
						<p>اللغة العربية</p>
					</section>*/}
					<div className="all-listed-courses-container">
						{
							coursesDB.filter(c => c.subject_id === 101).map((c, index) => 
								<div className="listed-course-wrapper">
									<div href="/" className="course-element">
										<div className="course-element-cover">
											<img src={c.cover_url} alt="course cover image" />
										</div>
										<div className="course-element-content">
											<div className="image-container">
												<img src={c.owner.img_url} alt="course teach profile picture" />
											</div>
											<div>
												<p className="title">{c.courseName}</p>
												<p className="ratting">{set_rating(c.ratting)}</p>
												<p className="timestamp">{c.timeStamp}</p>
												<p className="visits"><VisibilityIcon />{c.visits}</p>
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>

					{/*<section className="listed-courses-section" id="listed-courses-section-102">
						<p>التاريخ و الجغرافيا</p>
					</section>*/}
					<div className="all-listed-courses-container">
						{
							coursesDB.filter(c => c.subject_id === 102).map((c, index) => 
								<div className="listed-course-wrapper">
									<div href="/" className="course-element">
										<div className="course-element-cover">
											<img src={c.cover_url} alt="course cover image" />
										</div>
										<div className="course-element-content">
											<div className="image-container">
												<img src={c.owner.img_url} alt="course teach profile picture" />
											</div>
											<div>
												<p className="title">{c.courseName}</p>
												<p className="ratting">{set_rating(c.ratting)}</p>
												<p className="timestamp">{c.timeStamp}</p>
												<p className="visits"><VisibilityIcon />{c.visits}</p>
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>

					{/*<section className="listed-courses-section" id="listed-courses-section-103">
						<p>التربية المدنية</p>
					</section>*/}
					<div className="all-listed-courses-container">
						{
							coursesDB.filter(c => c.subject_id === 103).map((c, index) => 
								<div className="listed-course-wrapper">
									<div href="/" className="course-element">
										<div className="course-element-cover">
											<img src={c.cover_url} alt="course cover image" />
										</div>
										<div className="course-element-content">
											<div className="image-container">
												<img src={c.owner.img_url} alt="course teach profile picture" />
											</div>
											<div>
												<p className="title">{c.courseName}</p>
												<p className="ratting">{set_rating(c.ratting)}</p>
												<p className="timestamp">{c.timeStamp}</p>
												<p className="visits"><VisibilityIcon />{c.visits}</p>
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>

					{/*<section className="listed-courses-section" id="listed-courses-section-104">
						<p>العلوم الطبيعية و الحياة</p>
					</section>*/}
					<div className="all-listed-courses-container">
						{
							coursesDB.filter(c => c.subject_id === 104).map((c, index) => 
								<div className="listed-course-wrapper">
									<div href="/" className="course-element">
										<div className="course-element-cover">
											<img src={c.cover_url} alt="course cover image" />
										</div>
										<div className="course-element-content">
											<div className="image-container">
												<img src={c.owner.img_url} alt="course teach profile picture" />
											</div>
											<div>
												<p className="title">{c.courseName}</p>
												<p className="ratting">{set_rating(c.ratting)}</p>
												<p className="timestamp">{c.timeStamp}</p>
												<p className="visits"><VisibilityIcon />{c.visits}</p>
											</div>
										</div>
									</div>
								</div>
							)
						}
					</div>
					
				</div>
			</ProfileLayout>
		</div>
	)
}


export default AllCourses;