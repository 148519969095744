import { useState, useEffect } from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import './css/homepage.css'
//MUI
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//framer motion
import { motion } from 'framer-motion'
//react-helmet
import { Helmet } from 'react-helmet'

function HomePage(){
	const [carouselIndex, setCarouselIndex] = useState(0);

    const nextItem = () => {
        setCarouselIndex((prevIndex) => (prevIndex + 1) % 3);
    };

    const prevItem = () => {
        setCarouselIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
    };

	return(

		<div className="homepage">
			<Helmet>
				<meta charset="utf-8" />
				<title>الصفحة الرئيسية | أكادمية النجيب سكول</title>
				<link rel="canonical" href="https://najibschool.com/homepage" />
			</Helmet>
			<Nav/>
			<header className="homepage-header">
				<div className="main-div">
					<article>
						<div>
							<p className="title">أكادمية النجيب سكول</p>
							<p className="subtitle">الإختيار الأول  للدراسة في الجزائر</p>
						</div>
						<img
							className="blob"
							height="800"
							src="/images/blob.png"
							alt="png svg illustration of geo blob in css"
						/>
						<img
							className="picture"
							height="500"
							src="/images/kid-student.png"
							alt="png picture of student kid with books and bag and glasses"
						/>
					</article>
				</div>
				<div className="homepage-links">
					<FacebookIcon />
					<XIcon />
					<InstagramIcon />
					<LinkedInIcon />
				</div>
			</header>
			<section className="powers-section">
				<div id="power-1">
					<img src="/icons/books.svg" alt="png svg illustration of books" />
					<p className="title">المكتبة</p>
					<p className="sub-title">استكشف مجموعة واسعة من الموارد التعليمية</p>
				</div>
				<div id="power-2">
					<img src="/icons/school-triangle.png" alt="svg png illustration of school triangle" />
					<p className="title">الدروس</p>
					<p className="sub-title">تعلم خطوة بخطوة مع محتوى شامل ومفيد</p>
				</div>
				<div id="power-3">
					<img src="/icons/tv.svg" alt="svg png illustration of tv and show media" />
					<p className="title">البث المباشر</p>
					<p className="sub-title">انضم إلى جلسات تعليمية تفاعلية في الوقت الحقيقي</p>
				</div>
				<div id="power-4">
					<img src="/icons/money.svg" alt="svg png illustration of money and cash" />
					<p className="title">إكسب معنا</p>
					<p className="sub-title">إنظم إلى برنامج النجيب سكول و إكسب المال</p>
				</div>
			</section>

			<div className="biblio">
				<div className="biblio-content">
					<p className="title">النجيب سكول, مكتبتك الإلكترونية الشاملة</p>
					<p className="subtitle-1">✅ استمتع بقراءة وتحميل أكثر من <span>10,000</span> كتاب، امتحان، واختبار في مختلف التخصصات، كل ذلك بنقرة واحدة فقط في <a href="/">المكتبة</a></p>
					<p className="subtitle-2">✅ احصل على <span>موارد تعليمية</span> قيمة بصيغة PDF، وكن دائمًا على استعداد لتحقيق النجاح الأكاديمي في جميع مجالات المعرفة.</p>
					
				</div>
				<div className="biblio-img-container">
					<img
						src="/images/books.avif"
					/>
				</div>
			</div>

			<div className="livestream">
				<div className="hero">
					<p className="title">بث مباشر للدرس بطريقة تفاعلية</p>
					<p className="subtitle">استمتع بتجربة تعليمية تفاعلية ومثيرة، حيث يمكنك التعلم من معلمين خبراء في الوقت الحقيقي، والتواصل مع زملائك الطلاب من جميع أنحاء العالم. انضم إلينا وابدأ رحلتك التعليمية المذهلة اليوم</p>
					<button>أنشأ قناتك الآن مجانا</button>
				</div>
			</div>

			<div className="home-reviews">
				<button onClick={nextItem} ><ChevronRightIcon /></button>
				<div className="home-reviews-carousel">
		            <motion.div
		            	className="review"
		                initial={{ opacity: 0 }} // Start as transparent
		                animate={{ opacity: carouselIndex===0? 1 : 0}} // Fade in
		                exit={{ opacity: 0 }} // Fade out
		                transition={{ duration: 0.5 }}
		            >
		            <div className="img_container">
		            	<img
		            		src="/images/teachers/t5.jpg"
		            		alt="picture of a person giving a review to online school web service "
		            	/>
		            </div>
		            <p className="name">ع. سمير</p>
		            <p className="content">أكثر ما أعجبني في النجيب سكول هو المرونة في الدراسة. يمكنني التقدم في وتيرتي الخاصة، والدروس كانت مشوقة ومفيدة. الدعم الفني كان سريعًا في الرد على استفساراتي. أنصح أي شخص يبحث عن تعليم عالي الجودة أن يلتحق بهذه المدرسة</p>
		            <p className="stars">⭐ ⭐ ⭐ ⭐ ⭐</p>
		            </motion.div>
		            <motion.div
		            	className="review"
		                initial={{ opacity: 0 }} // Start as transparent
		                animate={{ opacity: carouselIndex===1? 1 : 0 }} // Fade in
		                exit={{ opacity: 0 }} // Fade out
		                transition={{ duration: 0.5 }}
		            >
		            <div className="img_container">
		            	<img
		            		src="/images/teachers/t3.jpg"
		            		alt="picture of a person giving a review to online school web service "
		            	/>
		            </div>
		            <p className="name">ب. أيمن</p>
		            <p className="content">كانت تجربتي رائعة مع الدورات الدراسية في النجيب سكول! المعلمون كانوا محترفين ومتعاونين بشكل كبير. المنهج كان منظمًا بشكل جيد، وأحببت الأنشطة التفاعلية التي ساعدتني على فهم المحتوى بشكل أفضل. حصلت على شهادتي في وقت قياسي ونجحت في الحصول على وظيفة جديدة بعد التخرج. أنصح الجميع بالتسجيل</p>
		           	<p className="stars">⭐ ⭐ ⭐ ⭐ ⭐</p>
		            </motion.div>
		            <motion.div
		            	className="review"
		                initial={{ opacity: 0 }} // Start as transparent
		                animate={{ opacity: carouselIndex===2? 1 : 0 }} // Fade in
		                exit={{ opacity: 0 }} // Fade out
		                transition={{ duration: 0.5 }}
		            >
		            <div className="img_container">
		            	<img
		            		src="/images/teachers/t2.jpg"
		            		alt="picture of a person giving a review to online school web service "
		            	/>
		            </div>
		            <p className="name">ج. توفيق</p>
		            <p className="content">النجيب سكول مكان رائع للتعلم! المواد كانت مفيدة للغاية، والتفاعل مع الطلاب الآخرين كان ممتعًا. بعض الدروس تحتاج إلى تحسين، لكن بشكل عام كانت تجربة تعليمية ممتازة. سأعود بالتأكيد لأخذ المزيد من الدورات</p>
		           	<p className="stars">⭐ ⭐ ⭐ ⭐ ⭐</p>
		            </motion.div>
	            </div>

	            <button onClick={prevItem} ><ChevronLeftIcon/></button>

			</div>

			<Footer />
		</div>
		)
}

export default HomePage;