import './css/course-view.css'
//layout
import ProfileLayout from '../../layouts/profileLayout'


//MUI



function CourseView(){
	return(
		<div className="course-view">
			<ProfileLayout>
				this is course view
			</ProfileLayout>
		</div>
	)
}


export default CourseView