import { useState } from 'react'
// css
import './css/courses-filter.css'

//MUI
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';


function CoursesFilter(){

	let [filterParams, setFilterParams] = useState({
		course_name: '',
		year: "0",
		level: "0",
		subject: "0",
	})

	

	function handleLevelChange(e) {
		// e.target --> value, name
		let {value} = e.target
		setFilterParams(prev => ({...prev, [e.target.name]:e.target.value }))
	}

	let c = 5

	return(
		<div className="courses-filter">

			<div className="parameter-container">
				<div className="search-container">
					<input placeholder="إسم الدرس" />
				</div>
			</div>

			<div className="parameter-container">
				<div className="search-container">
					<input placeholder="إسم الإستاذ" />
				</div>
			</div>

			<div className="parameter-container">
				<Select
			        labelId="level-select-labelid"
			        id="level-select-id"
			        value={filterParams.year}
			        label="level"
			        onChange={handleLevelChange}
			        name="level"
			      >
			        <MenuItem value={'0'}>السنة</MenuItem>
			        <MenuItem value={'2024'}>2024</MenuItem>
			        <MenuItem value={'2023'}>2023</MenuItem>
			        <MenuItem value={'2022'}>2022</MenuItem>
			        <MenuItem value={'2021'}>2021</MenuItem>
			        <MenuItem value={'2020'}>2020</MenuItem>
			        <MenuItem value={'2019'}>2019</MenuItem>
			        <MenuItem value={'2018'}>2018</MenuItem>
			        <MenuItem value={'2017'}>2017</MenuItem>
			        <MenuItem value={'2016'}>2016</MenuItem>
			        <MenuItem value={'2015'}>2015</MenuItem>
			        <MenuItem value={'2014'}>2014</MenuItem>
			        <MenuItem value={'2013'}>2013</MenuItem>
			        <MenuItem value={'2012'}>2012</MenuItem>
			        <MenuItem value={'2011'}>2011</MenuItem>
			        <MenuItem value={'2010'}>2010</MenuItem>
			        <MenuItem value={'2009'}>2009</MenuItem>
			        <MenuItem value={'2008'}>2008</MenuItem>
			        <MenuItem value={'2007'}>2007</MenuItem>
			        <MenuItem value={'2006'}>2006</MenuItem>
			        <MenuItem value={'2005'}>2005</MenuItem>
			    </Select>
		      </div>

			<div className="parameter-container">
				<Select
			        labelId="level-select-labelid"
			        id="level-select-id"
			        value={filterParams.subject}
			        label="level"
			        onChange={handleLevelChange}
			        name="level"
			      >
			        <MenuItem value={"0"}>المادة</MenuItem>
			        <MenuItem value={'0ap'}>اللغة العربية</MenuItem>
			        <MenuItem value={'1ap'}>اللغة الإنجليزية</MenuItem>
			        <MenuItem value={'2ap'}>العلوم الطبيعية و الحياة</MenuItem>
			        <MenuItem value={'3ap'}>التربية المدنية</MenuItem>
			        <MenuItem value={'4ap'}>التربية الاسلامية</MenuItem>
			        <MenuItem value={'5ap'}>التربية الفنية</MenuItem>
			        <MenuItem value={'1am'}>الإعلام الآلي</MenuItem>
			        <MenuItem value={'2am'}>التاريخ</MenuItem>
			        <MenuItem value={'3am'}>الجغرافيا</MenuItem>
			    </Select>
		      </div>

			<div className="parameter-container">
				<Select
			        labelId="level-select-labelid"
			        id="level-select-id"
			        value={filterParams.level}
			        label="level"
			        onChange={handleLevelChange}
			        name="level"
			      >
			        <MenuItem value={"0"}>المستوى</MenuItem>
			        <MenuItem value={'0ap'}>قسم التحضيري</MenuItem>
			        <MenuItem value={'1ap'}>أولى إبتدائي</MenuItem>
			        <MenuItem value={'2ap'}>ثانية إبتدائي</MenuItem>
			        <MenuItem value={'3ap'}>ثالثة إبتدائي</MenuItem>
			        <MenuItem value={'4ap'}>رابعة إبتدائي</MenuItem>
			        <MenuItem value={'5ap'}>خامسة إبتدائي</MenuItem>
			        <MenuItem className="courses-filter-cinq-exam-li" value={'cinq'}>إمتحان شهادة الإبتدائية</MenuItem>
			        <MenuItem value={'1am'}>الأولى متوسط</MenuItem>
			        <MenuItem value={'2am'}>الثانية متوسط</MenuItem>
			        <MenuItem value={'3am'}>الثالثة متوسط</MenuItem>
			        <MenuItem value={'4am'}>الرابعة متوسط</MenuItem>
			        <MenuItem value={'5am'}>الخامسة متوسط</MenuItem>
			        <MenuItem className="courses-filter-bem-exam-li" value={'BEM'}>إمتحانا شهادة المتوسط</MenuItem>
			        <MenuItem value={'1as'}>الأولى ثانوية</MenuItem>
			        <MenuItem value={'2as'}>الثانية ثانوي</MenuItem>
			        <MenuItem value={'3as'}>الثالثة ثانوي</MenuItem>
			        <MenuItem className="courses-filter-bac-exam-li" value={'BAC'}>إمتحان شهادة البكالوريا</MenuItem>
			    </Select>
		      </div>
		      <button className="search-button" >
		      	<SearchIcon />
		      	<p>بحث</p>
		      </button>
		</div>
	)
}

export default CoursesFilter;