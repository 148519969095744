import { useNavigate } from 'react-router-dom'
import './css/latest-courses.css'
//MUI
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
// import VisibilityIcon from '@mui/icons-material/Visibility';

function LatestCourses(){
	let navigate = useNavigate()

	let data = [
		{
			courseName: "course of Math",
			cover_url:"/images/c1.jpg",
			ratting: 4,
			timeStamp: "04-12-2023",
			visits: 789,
			owner: {
				name: "yassine",
				spec: "math",
				img_url: "/images/teachers/t1.jpg"
			}
		},
		{
			courseName: "course of Arabic",
			cover_url:"/images/c2.jpg",
			ratting: 4,
			timeStamp: "04-12-2023",
			visits: 789,
			owner: {
				name: "yassine",
				spec: "math",
				img_url: "/images/teachers/t2.jpg"
			}
		},
		{
			courseName: "course og Physics",
			cover_url:"/images/c3.jpeg",
			ratting: 4,
			timeStamp: "04-12-2023",
			visits: 789,
			owner: {
				name: "yassine",
				spec: "math",
				img_url: "/images/teachers/t3.jpg"
			}
		}
	]

function set_rating(rate){
	let light_stars = rate
	let dark_stars = 5 - rate

	let light_array = Array.from({ length:light_stars}, (_, index) => <StarIcon />)
	let dark_array = Array.from({ length:dark_stars}, (_, index) => <StarBorderIcon />)
	let full_array = [...light_array, ...dark_array]
	return full_array
}


	return(
		<div className="latest-courses">
			<header className="latest-courses-header">
				<div>
					<AutoStoriesIcon />
					آخر الدروس المرفوعة
				</div>
				<button className="more-courses" onClick={() => navigate('/student/all-courses')}>
					<p>المزيد من الدروس</p>
					<ArrowBackIosNewIcon />
				</button>
			</header>
			<article className="courses-container">
				{
					data.map(c => 
						<a href="/" className="course-element">
							<div className="course-element-cover">
								<img src={c.cover_url} alt="course cover image" />
							</div>
							<div className="course-element-content">
								<div className="img-cont">
									<img src={c.owner.img_url} alt="course teach profile picture" />
								</div>
								<div>
									<p className="title">{c.courseName.length>28?c.courseName.substring(0,28):c.courseName}</p>
									<p className="ratting">{set_rating(c.ratting)}</p>
								</div>
							</div>
						</a>
					)
				}
			</article>
		</div>
	)
}

export default LatestCourses;