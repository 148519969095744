import { useState } from 'react'
import { useLocation } from "react-router-dom"
//css
import './css/listing.css'
//components
import Nav from '../components/Nav'
import Footer from '../components/Footer'
//MUI
import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function Listing(){
	let [preview, setPreview] = useState({
		status: false,
		source: ""
	})
    const query = useQuery();
    let bnl = query.get("bnl")
    let subject = query.get("subject")
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const data = [
    	{
    		filename: 'd',
    	},
    	{
    		filename: 'math test for 2 term',
    	},
    	{
    		filename: 'math test for 3 term',
    	},
    	{
    		filename: 'math test for 4 term',
    	},
    	{
    		filename: 'math test for 5 term',
    	},
    ]

    function handleTriggerDownloadStart(event){
    	let download_name = event.currentTarget.getAttribute('data-download-name')
    	let pdf_source = `/ftp/study/1ap/math/${download_name}.pdf`
        const a = document.createElement('a');
        a.href = pdf_source;
        a.download = download_name; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a)
    }

	return(
		<div className="listing">
			<Nav/>
			<div>
				<ul>
				{
					data.map(pdf => 
						<li className="listed-pdf">
							<p download="d">{pdf.filename}</p>
							<div>
								<a href="/ftp/study/1ap/math/d.pdf" target="_blank" ><RemoveRedEyeIcon /></a>
								<DownloadIcon data-download-name={pdf.filename} onClick={(e) => handleTriggerDownloadStart(e)} />
							</div>
						</li>
					)
				}
				</ul>
			</div>
			<Footer/>
		</div>
	)
}

export default Listing