import './css/profile-nav.css'
import { useState } from "react"
// MUI
import Stack from '@mui/material/Stack'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkIcon from '@mui/icons-material/Work';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import StorefrontIcon from '@mui/icons-material/Storefront';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import Badge from '@mui/material/Badge'
// import Dialog from '@mui/material/Dialog';
// import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';
//new icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import GroupsIcon from '@mui/icons-material/Groups';import LiveTvIcon from '@mui/icons-material/LiveTv';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

function ProfileNav(){
    let [notif, setNotif] = useState(false)
    let [localToken, setLocalToken] = useState("")
    let [localNotifications, setLocalNotifications] = useState({
        count: null,
        list: []
    })
    // let userRedux = useSelector(state => state.user)
    // console.log(userRedux)

    function handleClose(){
        setNotif(false)
    }

    const NavStylres = {
        height:"40px",
        background:"#0384fc",
        direction:"rtl",
        padding:"12px",
        color:"#fff",
        fontWeight:"bold"
    }

    return( 
        <>
        <div className="account-not-confirmed">
            <p>🚫 حسابك غير مفعل, لن تتحصل على الميزات التالية: <span>نشر كتب</span> <span>نشر دروس</span> <span>بدأ بث مباشر</span> <span>مطالبة بأرباح</span> ... يرجي تفعيل الحساب من <a href="/">هنا</a></p>
            <p><a href="">زيارة مركز النجيب سكول للمساعدة</a></p>
        </div>
        <nav className="profile_nav">
                <div className="profilenav_insights_container">
                    <a className="account-avatar" href="/">
                        <div className="img-cont">
                            <img src="/student-avatar.jpg" alt="png illustration of a man" />
                        </div>
                        <div>
                            <p className="full-name">عين قوير ياسين</p>
                            <p className="level">أستاذ التعليم المتوسط</p>
                        </div>
                    </a>                    
                    <div className="insight">
                            {/*<LaptopChromebookIcon sx={{color:"#FFBF00"}} />*/}
                            <img src="/icons/rocket.svg" alt="svg illustration of a rocket" />
                            <p className="name">التعليمية</p>
                             {/*<KeyboardArrowDownIcon />*/}
                       {/* <div className="insight-dropdown" id="insight-dropdown-1">
                            <div className="profile-nav-triangle" ></div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/insight-2.svg" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">دروس مسجلة</p>
                                    <p className="desc">مكتبة شاملية بالدروس المسجلة</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon /></a>
                                </div>
                            </div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/insight-3.svg" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">إمتحانات قياسية </p>
                                    <p className="desc">إختبر نفسك في مادة معينة</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon /></a>
                                </div>
                            </div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/insight-4.svg" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">إختبارات و فروض</p>
                                    <p className="desc">إمتحانات قياسية مجانية متوفرة</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon /></a>
                                </div>
                            </div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/insight-1.svg" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">إستكشاف</p>
                                    <p className="desc">إسنكشف المزيد في النجيب سكول</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon/></a>
                                </div>
                            </div>
                        </div>*/}
                    </div>

                    <div className="insight">
                        <img src="/icons/team.svg" alt="svg illustration of a rocket" />
                        <p className="name">المجموعات</p>
                        {/*<KeyboardArrowDownIcon />*/}
                        {/*<div className="insight-dropdown" id="insight-dropdown-2">
                            <div className="profile-nav-triangle" ></div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/groups.png" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">مجموعة اللغة العربية</p>
                                    <p className="desc">إسنكشف المزيد في النجيب سكول</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon/></a>
                                </div>
                            </div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/groups.png" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">مجموعة الرياضيات </p>
                                    <p className="desc">إسنكشف المزيد في النجيب سكول</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon/></a>
                                </div>
                            </div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/groups.png" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">مجموعة اللعة الفرنسية</p>
                                    <p className="desc">إسنكشف المزيد في النجيب سكول</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon/></a>
                                </div>
                            </div>
                            <div className="grid-element">
                                <div className="svg-container">
                                    <img src="/groups.png" alt="png illustration of nav bar icon" />
                                </div>
                                <div>
                                    <p className="title">مجموعة الفيزياء</p>
                                    <p className="desc">إسنكشف المزيد في النجيب سكول</p>
                                    <a className="more" href="/">تعرف على المزيد<KeyboardBackspaceIcon/></a>
                                </div>
                            </div>
                            <div className="more-groups">
                                <a href="/">المزيد من المجموعات <KeyboardBackspaceIcon style={{fontSize:"1.2rem",color:"grey"}} /></a>
                            </div>
                        </div>*/}
                    </div>

                    <a href="/stream/broadcast" className="insight">
                        <img src="/icons/tv.svg" alt="svg illustration of a rocket" />
                        <p className="name">إبدأ البث</p>
                    </a>

                    {/*<a href="/websites/all-websites" className="insight">
                        <img src="/icons/books.svg" alt="svg illustration of a rocket" />
                        <p className="name">مواضيع</p>
                    </a>*/}
                </div>

                <div className="buttons_container">
                    <div className="search_container">
                        <input type="text" placeholder="بحث عن درس, كتاب, أستاذ, لايف,محاضرة" />
                        <button>
                            <SearchIcon />
                        </button>
                    </div>

                    <a href="/settings">
                        <Stack title="الإعدادت">
                            <SettingsIcon  />
                        </Stack>
                    </a>
                    
                    <Stack title="الخروج">
                        <ExitToAppIcon  />
                    </Stack>
                </div>
                    
        </nav>
        </>
    )
}

export default ProfileNav;
