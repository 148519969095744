import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './'
// import ProfileLayout from './layouts/profileLayout'
//Routes
import HomePage from './routes/HomePage'
import StudentOverview from './routes/student/overview'
import AllTeachers from './routes/teachers/AllTeachers'
import TeacherView from './routes/teachers/TeacherView'
import TeacherOverview from './routes/teacher/overview'
import AllCourses from './routes/student/all-courses'
import CourseView from './routes/student/course-view'
import Login from './routes/Login'
import Library from './routes/Library'
import Listing from './routes/Listing'
import AboutUs from './routes/AboutUs'
import AllStreams from './routes/streams/all-streams'
// import Stream from './routes/streams/stream'
// import Live from './routes/Live'
// import PeerRoute from './routes/streams/peer'
// import CPeerRoute from './routes/streams/cpeer'
import Broadcast from './routes/streams/broadcast'
import Subscriber from './routes/streams/subscriber'

function App(){
    return(
        <Router>
            <Routes>
                    {/*Static*/}
                    <Route path="/" element={<Library />} />
                    <Route path="/library" element={<Library />} />
                    <Route path="/homepage" element={<HomePage />} />
                    <Route path="/library/listing" element={<Listing />} />
                    {/*<Route path="/live" element={<Live />} />*/}
                    
                    {/*Dashboard*/}
                    <Route path="/student/overview" element={<StudentOverview />} />
                    <Route path="/student/all-teachers" element={<AllTeachers />} />
                    <Route path="/student/all-teachers/:slug" element={<TeacherView />} />
                    <Route path="/student/all-courses" element={<AllCourses />} />
                    <Route path="/student/all-courses/:slug" element={<CourseView />} />
                    <Route path="/teacher/overview" element={<TeacherOverview />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/student/streams/all-streams" element={<AllStreams />} />
                   
                    {/*Live*/}
                    <Route path="/stream/broadcast" element={<Broadcast />} />
                    <Route path="/stream/watch" element={<Subscriber />} />
            </Routes>
        </Router>
    )
}


export default App;