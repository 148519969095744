
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import './css/about-us.css'
//react-helmet
import { Helmet } from 'react-helmet'

function AboutUs(){
	return(
		<div>
			<Helmet>
				<meta charset="utf-8" />
				<title>من نحن | أكادمية النجيب سكول</title>
				<link rel="canonical" href="https://najibschool.com/about-us" />
			</Helmet>
			<Nav />
			<header className="aboutus-header">
				<div class="hero">
					<p className="title">من نحن ؟</p>
					<p className="subtitle">نحن ملتزمون بتقديم تعليم متميز ومبتكر، حيث نسعى لتزويد طلابنا بالمعرفة والمهارات اللازمة لتحقيق طموحاتهم. انضم إلينا في رحلتنا لتحسين التعليم وتوفير بيئة تعليمية ملهمة.</p>
				</div>
				<img
					id="leaf-2"
					src="/images/leaf-2.png"
					alt="png illstartion of a leaf drawn in modern asthetic art style"
					height="80px"
				/>
				<img
					id="leaf-1"
					src="/images/leaf-1.png"
					alt="png illstartion of a leaf drawn in asthetic art style"
					height="80px"
				/>
				{/*<img
					id="book-img"
					src="/images/book-top.png"
					alt="png svg illstartion of an open book with glasses on top"
					height="400px"
				/>
				<img
					id="coffee-img"
					src="/images/coffee.png"
					alt="png svg picture of a cup of black coffee"
					height="500px"
				/>*/}
			</header>

			<section className="mession">
				<div class="mession-header">
					ماهي مهمتنا ؟
				</div>
				<main className="mession-main">
						<div class="mession-item">
							<img
								src="/icons/aboutus-1.svg"
								alt="png svg illustration of an icon"
							/>
	            <div id="title-1" class="title">تعليم مبتكر</div>
	            <div class="subtitle">نحن نسعى لتقديم أساليب تعليمية مبتكرة تواكب التطورات الحديثة في مجال التعليم. نستخدم تكنولوجيا متقدمة ووسائل تعليمية جديدة تساعد الطلاب على فهم المواد بشكل أفضل، مما يعزز من قدرتهم على التعلم الذاتي والابتكار في التفكير.</div>
		        </div>
		        <div class="mession-item">
		        	<img
								src="/icons/aboutus-2.svg"
								alt="png svg illustration of an icon"
							/>
		            <div id="title-2" class="title">تعليم مرن</div>
		            <div class="subtitle">نوفر بيئة تعليمية مرنة تتيح للطلاب التعلم في الوقت الذي يناسبهم. هذا النظام يمنح الطلاب الحرية في اختيار أوقات دراستهم، مما يساعدهم على تحقيق توازن أفضل بين الدراسة والحياة الشخصية، ويشجعهم على الالتزام بالتعليم بشكل أكبر.</div>
		        </div>
		        <div class="mession-item">
		        	<img
								src="/icons/aboutus-3.svg"
								alt="png svg illustration of an icon"
							/>
		            <div id="title-3" class="title">توفير موارد متنوعة</div>
		            <div class="subtitle">نقدم مجموعة واسعة من المواد التعليمية والمصادر لدعم تعلم الطلاب، تشمل الكتب الإلكترونية، مقاطع الفيديو، والاختبارات التفاعلية. تهدف هذه الموارد إلى تعزيز الفهم العميق للمواضيع المختلفة وتلبية احتياجات جميع أنماط التعلم.</div>
		        </div>
		        <div class="mession-item">
		        	<img
								src="/icons/aboutus-4.svg"
								alt="png svg illustration of an icon"
							/>
		            <div id="title-4" class="title">دعم مستمر</div>
		            <div class="subtitle">نسعى لتقديم الدعم المستمر للطلاب من خلال تفاعل مباشر مع المعلمين. نحن نؤمن بأن التعلم لا يتوقف عند انتهاء الدروس، لذلك نقدم جلسات دعم إضافية، ومساعدة فردية لضمان تحقيق كل طالب لأقصى استفادة من تجربته التعليمية.</div>
		        </div>
		        <div class="mession-item">
		        	<img
								src="/icons/aboutus-5.svg"
								alt="png svg illustration of an icon"
							/>
		            <div id="title-5" class="title">تطوير مهارات الحياة</div>
		            <div class="subtitle">نهدف إلى تعزيز مهارات الحياة الأساسية لدى الطلاب ليستعدوا لمواجهة التحديات المستقبلية. نركز على تنمية مهارات التفكير النقدي، والقيادة، والتعاون، مما يساعد الطلاب على أن يصبحوا مواطنين فعالين في مجتمعاتهم.</div>
		        </div>
		        <div class="mession-item">
		        	<img
								src="/icons/aboutus-6.svg"
								alt="png svg illustration of an icon"
							/>
		            <div id="title-6" class="title">تعلم شامل</div>
		            <div class="subtitle">نؤمن بأهمية التعلم الشامل الذي يتضمن تعزيز التفكير النقدي والإبداع. نقدم برامج تعليمية متكاملة تهدف إلى تطوير قدرات الطلاب في جميع المجالات، مما يمكنهم من استكشاف اهتماماتهم وتحقيق طموحاتهم الشخصية والمهنية.</div>
		        </div>
				</main>
			</section>

			<Footer />
		</div>
	)
}


export default AboutUs;