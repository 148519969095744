import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//css
import './css/library.css'
//components
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import LibraryFilter from '../components/LibraryFilter'
//MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
//react-helmet
import { Helmet } from 'react-helmet'

function Library(){
	let navigate = useNavigate()
	let [subjectDialog, setSubjectDialog] = useState({
		status: false,
		branchAndLevel: ""
	})

	function handleOpenSubjectDialog(event){
		let branchAndLevel = event.currentTarget.getAttribute('data-branch-and-level')
		setSubjectDialog(prev => ({
			status: true,
			branchAndLevel
		}))
	}

	function handleCloseSubjectDialog(){
		setSubjectDialog(prev => ({
			status: false,
			branchAndLevel: ""
		}))
	}

	function handleRedirectToDocsListing(event){
		let subject = event.currentTarget.id
		navigate(`listing?bnl=${subjectDialog.branchAndLevel}&subject=${subject}`)
	}

	return(
	 <div className="library">
	 <Helmet>
		<meta charset="utf-8" />
		<title>المكتبة | أكادمية النجيب سكول</title>
		<link rel="canonical" href="https://najibschool.com/library" />
	</Helmet>
	 <Nav />

	 <Dialog
	 	open={subjectDialog.status}
	 	className="library-select-subject-dialog"
	 	sx={{
    		"& .MuiPaper-root":{
				margin:0,
				minHeight:'300px',
				minWidth:'800px',
    		}
    	}}
	 	onClose={handleCloseSubjectDialog}>
	 	<DialogTitle style={{backgroundColor:"#0384fc",color:"#fff"}}>إختيار المادة</DialogTitle>
	 	<section>
		 	<div id="math" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >الرياضيات</div>
			<div id="arab" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >اللغة العربية</div>
			<div id="sience" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >العلوم</div>
			<div id="french" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >اللغة الفرنسية</div>
			<div id="english" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >اللغة الانجليزية</div>
			<div id="histo" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >التاريخ</div>
			<div id="geo" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >الجغرافيا</div>
			<div id="art" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >التربية الفنية</div>
			<div id="sport" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >التكنولوجيا</div>
			<div id="techno" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >التربية الإسلامية</div>
			<div id="islamic" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >المواد الاجتماعية</div>
			<div id="social" className="library-subject-entry-card-name" onClick={e => handleRedirectToDocsListing(e)} >التربية البدنية</div>
		</section>
	 </Dialog>

	 <header className="library-header">
	 <div className="library-header-hero">
	 <LibraryFilter />
	 </div>
	 </header>

	 <div className="branches-heading">
	 <p>الاطوار الدراسية</p>
	 </div>

	 <div className="branches-container">


	 <div className="branch" id='branch-2'>
	 <div class="pre-header">
	 الطور الإبتدائي
	 </div>
	 <header>
	 </header>
		 <ul>
			 <li>
			 	<p data-branch-and-level="0ap" onClick={e => handleOpenSubjectDialog(e)}>القسم التحضيري</p>
			 </li>
			 <li>
				 <p data-branch-and-level="1ap" onClick={e => handleOpenSubjectDialog(e)}>السنة الأولى</p>
			 </li>
			 <li>
				 <p data-branch-and-level="2ap" onClick={e => handleOpenSubjectDialog(e)}>السنة الثانية</p>
			 </li>
			 <li>
				 <p data-branch-and-level="3ap" onClick={e => handleOpenSubjectDialog(e)}>السنة الثالثة</p>
			 </li>
			 <li>
				 <p data-branch-and-level="4ap" onClick={e => handleOpenSubjectDialog(e)}>السنة الرابعة</p>
			 </li>
			 <li>
				 <p data-branch-and-level="5ap" onClick={e => handleOpenSubjectDialog(e)}>السنة الخامسة</p>
			 </li>
		 </ul>
	 </div>

	 <div className="branch" id='branch-3'>
	 <div class="pre-header">
	 الطور المتوسط
	 </div>
	 <header>
	 </header>
	 	<ul>
			 <li>
				 <p data-branch-and-level="1am" onClick={e => handleOpenSubjectDialog(e)}>السنة الأولى</p>
			 </li>
			 <li>
				 <p data-branch-and-level="2am" onClick={e => handleOpenSubjectDialog(e)}>السنة الثانية</p>
			 </li>
			 <li>
				 <p data-branch-and-level="3am" onClick={e => handleOpenSubjectDialog(e)}>السنة الثالثة</p>
			 </li>
			 <li>
				 <p data-branch-and-level="4am" onClick={e => handleOpenSubjectDialog(e)}>السنة الرابعة</p>
			 </li>
		 </ul>
	 </div>

	 <div className="branch" id='branch-4'>
	 <div className="pre-header">
	 	الطور الثانوي
	 </div>
	 <header>
	 </header>
		 <ul>
			 <li>
				 <p data-branch-and-level="1as" onClick={e => handleOpenSubjectDialog(e)}>السنة الأولى</p>
			 </li>
			 <li>
				 <p data-branch-and-level="2as" onClick={e => handleOpenSubjectDialog(e)}>السنة الثانية</p>
			 </li>
			 <li>
				 <p data-branch-and-level="3as" onClick={e => handleOpenSubjectDialog(e)}>السنة الثالثة</p>
			 </li>
		 </ul>
	 </div>

	 </div>

	 <div className="ad-space">
	 <div>
	 	مساحة اعلانية
	 </div>
	 </div>

	 <Footer />

	 </div>
	 )
}


export default Library