import { useState, useEffect } from "react"
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import "./css/login.css"
// import { useParams } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
//MUI
import Alert from '@mui/material/Alert';
//react-helmet
import { Helmet } from 'react-helmet'

function Login(){
	let navigate = useNavigate()
	let [loginType, setLoginType] = useState(null)
	let [loginCredentials, setLoginCredentials] = useState({
		email: null,
		password: null
	})
	let [alert, setAlert] = useState({
		status: false,
		message: null,
		type: null
	})

	useEffect(() => {
		let urlSearchParams = new URLSearchParams(window.location.search)
		let loginTypeValue = urlSearchParams.get("t")
		setLoginType(loginTypeValue)
	}, [loginType])

	function handleLogin(){
			handleStudentLogin()			
	}

	function handleStudentLogin(){
		let {email, password} = loginCredentials
		if( email==="prof@gmail.com" || "prof" && password==="123" ) {
			navigate('/student/overview')
			console.log(true)
		} else {
			// console.log(false)
			setAlert({
				status:true,
				message: "البريد  الالكتروني او كلمة السر حاطئة",
				type: "error"
			})
		}
	}

	function handleTeacherLogin(){
		return "handle teacher login"
	}

	function notav(){
		setAlert({
			status:true,
			message: "هذه الميزة غير متاحة بعد",
			type: "warning"
		})
	}

	return(
		<div>
			<Helmet>
				<meta charset="utf-8" />
				<title>تسجيل الدخول | أكادمية النجيب سكول</title>
				<link rel="canonical" href="https://najibschool.com/login" />
			</Helmet>
			{alert.status && <Alert style={{position:'fixed',bottom:'5%',right:'5%',zIndex:5,cursor:"pointer"}} onClick={() => setAlert({status:false})} severity={alert.type}>{alert.message}</Alert>}
			<Nav/>
			<div className="login-container">
				<div className="hero">
					{/*{
						loginType==="s"?
						<img src="/student-avatar.svg" alt="png illustration for a man" />
						:
						<img src="/teacher-avatar.svg" alt="png illustration for a man" />
					}*/}
					
					<div className="login-header">الدخول بصفة {loginType==="s"?"طالب":"أستاذ"}</div>

					<div className="login-as">
						<div onClick={notav}>
							<img src="/icons/fsvg.svg" alt="png svg illustration for facebook logo and icon" />
							<p>الدخول عن طريق فايسبوك</p>
						</div>
						<div onClick={notav}>
							<img src="/icons/gsvg.svg" alt="png svg illustration for google logo and icon" />
							<p>الدخول عن طريق جوجل</p>
						</div>
					</div>

					<p style={{color:"grey",marginBottom:"8px"}}>أو الدخول من خلال حسابك الخاص</p>

					<input
						name="email"
						value={loginCredentials.email}
						onChange={(e) => setLoginCredentials(
							prev => ({...prev, [e.target.name]:e.target.value})
						)}
						placeholder="إسم المستخدم أو البريد الإلكتروني" />
					<input
						name="password"
						value={loginCredentials.password}
						onChange={(e) => setLoginCredentials(
							prev => ({...prev, [e.target.name]:e.target.value})
						)}
						placeholder="كلمة المرور"
						type="password"
						/>
					<p style={{color:"grey",textAlign:"left",textDecoration:"underline",cursor:"pointer",width:"90%"}}>لقد نسيت كلمة السر</p>
					<button 
						className="login-btn"
						onClick={handleLogin}
					>تسجيل الدخول</button>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default Login;