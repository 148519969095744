//styles
import "./css/profile-sidebar.css"
import { useNavigate } from "react-router-dom"
//Mui
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Groups2Icon from '@mui/icons-material/Groups2';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';


function ProfileSidebar(){
	let navigate = useNavigate()
	return(
		<aside className="profile-sidebar">
			<ul>
				<li onClick={() => navigate('/student/overview')}>
					<HomeIcon />
					<p>الصفحة الرئيسية</p>
				</li>
				<li onClick={() => navigate('/youtube.com')}>
					<SchoolIcon />
					<p>الدروس و المحاضرات</p>
				</li>
				<li onClick={() => navigate('/')}>
					<HistoryEduIcon />
					<p>الإمتحانات</p>
				</li>
				<li onClick={() => navigate('/')}>
					<SquareFootIcon />
					<p>الوحدات التعليمية</p>
				</li>
				<li onClick={() => navigate('/')}>
					<Groups2Icon />
					<p>الأطوار الدراسية</p>
				</li>
				<li onClick={() => navigate('/')}>
					<AccessibilityNewIcon />
					<p>الأساتذة و المعلمون</p>
				</li>
				<li onClick={() => navigate('/')}>
					<ChecklistRtlIcon />
					<p>الرزنامة الدراسية</p>
				</li>
			</ul>
			<div className="sidebar-footer">
				<img src="/logo.png" alt="png logo of nadjib school online" />
				<div>
					<p>النجيب سكول أونلاين</p>
					<p className="subtitle">مركز الدعم و المساعدة</p>
				</div>
			</div>
		</aside>
	)
}

export default ProfileSidebar;