// css
import './css/events-loader.css'


function EventsLoader(){
	return(
		<div className="events-loader">
			<header>
				<img src="/icons/calender.svg" alt="svg icon of a calender" />
				<p>الأحداث القدمة</p>
			</header>
			<div className="upcoming-event">
				<img src="/icons/event.svg" alt="png illustrationof upcoming event" />
				<div className="upcoming-event-body">
					<p>
						مراجعة عامة في الرياضيات
					</p>
					<div className="upcoming-event-footer">
						<div className="upcoming-event-timing">
							<p>الأستاذ إبراهيم</p>
							<p> التوقيت 13:00</p>
						</div>
					</div>
				</div>
			</div>
			<div className="upcoming-event">
				<img src="/icons/event.svg" alt="png illustrationof upcoming event" />
				<div className="upcoming-event-body">
					<p>
						مراجعة عامة في الرياضيات
					</p>
					<div className="upcoming-event-footer">
						<div className="upcoming-event-timing">
							<p>الأستاذ إبراهيم</p>
							<p> التوقيت 13:00</p>
						</div>
					</div>
				</div>
			</div>
			<div className="upcoming-event">
				<img src="/icons/event.svg" alt="png illustrationof upcoming event" />
				<div className="upcoming-event-body">
					<p>
						مراجعة عامة في الرياضيات
					</p>
					<div className="upcoming-event-footer">
						<div className="upcoming-event-timing">
							<p>الأستاذ إبراهيم</p>
							<p> التوقيت 13:00</p>
						</div>
					</div>
				</div>
			</div>
			<div className="upcoming-event">
				<img src="/icons/event.svg" alt="png illustrationof upcoming event" />
				<div className="upcoming-event-body">
					<p>
						مراجعة عامة في الرياضيات
					</p>
					<div className="upcoming-event-footer">
						<div className="upcoming-event-timing">
							<p>الأستاذ إبراهيم</p>
							<p> التوقيت 13:00</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EventsLoader;