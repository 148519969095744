//css
import "../css/all-streams.css"
import ProfileLayout from '../../layouts/profileLayout.jsx'
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

function AllStreams(){
    const socket = io('https://nadjib-school-online-server-2.onrender.com');
    // const socket = io('https://192.168.1.36:5000');
    // const socket = io('https://localhost:5000');
    const [streams, setStreams] = useState([]);
    useEffect(() => {
    	socket.emit('poll-active-streams', (active_streams_arr) => {
    		console.log(active_streams_arr)
    	})

        // Listen for active streams updates
        socket.on('active-streams', (data) => {
        	console.log(data)
            setStreams(data);
        });

        // console.log(streams)

        socket.on('removed-stream-id', (RSid) => {
        	console.log(RSid)
        	setStreams(prev => prev.streamId !== RSid);
        });

        // return () => {
        //     socket.off('active-streams'); // Clean up listener on unmount
        // };
    }, []);

    const joinStream = (streamId) => {
        // Logic to join the stream
        socket.emit('join-stream', { streamId });
    };


	return(
		<div className="all-streams">
			<ProfileLayout>
				<header>
					<a id="route-to-stream" href="stream">إبدأ بالبث</a>
				</header>

				<div>
				<ul>
                {streams.length>0?
                streams.map((stream, index) => (
                    <li key={index} className="stream-element" key={stream.streamId}>
                        <div>
                            <a href={stream.id}>
                        	<p>{stream.teacher_fullname}</p>
                        	<p>{stream.subject}</p>
                            </a>
                        </div>
                        <div>
                        	<p>{stream.level}</p>
                        	<p>{stream.started_at}</p>
                        </div>
                        <div className="join-btn-container">
                        	<button className="join-btn" onClick={() => joinStream(stream.streamId)}>إنظم للمحاضرة</button>
                        </div>
                    </li>
                ))
                :
                <div className="no-active-streams">
                	<img src="/icons/satelite.svg" alt="svg png illustration of satelite icon" />
                	<p>لايوجد أي بث حاليا</p>
                </div>
            	}
            </ul>
				</div>

			</ProfileLayout>
		</div>
	)
}


export default AllStreams;

